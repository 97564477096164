var $window;
var $navbar;
var $header;
var $navbarheight = 126;
var $navbarminheight = 70;
var $navbarsubtop = 80;
var $navbarsubtopmin = 51;
var $logoheight = 97;
var $logominheight = 45;
var $logo;
var $navbarsub;
var $navbarshadow;


$(document).ready(function() {
    $window = $(window);
    $header = $('header');
    $navbarshadow = $('.header--shadow');
    $navbar = $header.find('.navbar');
    $navbarsub = $header.find('.dropdown-menu');
    $logo = $header.find('.logo');
    initContent();
});

function initContent() {
    animateContent();
    $window.on('scroll', function (e) {
        animateScrollSlower(e);
        animateMenue(e);
    });
    $('.video--details-btn').on('click', function() {
        var video = $(this).data('url');
        if($(window).outerWidth() < 768) {
            video = $(this).data('urlmobil');
        }
        $('.videooverlay').remove();
        $('body').append('<div id="videooverlay"><div id="closevideoverlay"></div><div class="embed-responsive embed-responsive-16by9"><video id="detailsvideo" autoplay="true" loop="true" preload="auto" muted="" playsinline src="'+video+'" </div></div>');
        setTimeout(function() {
            $('#videooverlay').addClass('videooverlayeffekt');
        }, 50);
        $('#closevideoverlay').off('click').on('click', function() {
            $('#videooverlay').remove();
        });
    });
}

function animateContent() {
    $('.ani-fade').each( function() {
       $(this).addClass('ani-fade-in');
    });
}

function animateScrollSlower(e) {
    var scrollhoehe = e.currentTarget.scrollY / 3;

    $('.slowerscroll').css('transform','translateY(+'+scrollhoehe+'px)');
}

function animateMenue(e) {
    var scrollhoehe = e.currentTarget.scrollY;

    var navbarTranslateTop = scrollhoehe / 4;
    var navheighttmp = $navbarheight - navbarTranslateTop;
    if(navheighttmp < $navbarminheight) navheighttmp = $navbarminheight;


    $navbar.css('height', navheighttmp+'px');
    $navbarshadow.css('top', navheighttmp+'px');

    var navsubtoptmp = $navbarsubtop - (scrollhoehe / 8);
    if(navsubtoptmp < $navbarsubtopmin) navsubtoptmp = $navbarsubtopmin;

    $navbarsub.css('top', navsubtoptmp+'px');


    var logoheighttmp = $logoheight - (scrollhoehe / 4);
    if(logoheighttmp < $logominheight) logoheighttmp = $logominheight;

    $logo.css('height', logoheighttmp+'px');
}
